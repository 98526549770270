<template>
  <v-container fluid class="pa-0">
    <v-text-field
      :label="label"
      v-model="localMdl"
      filled
      :prepend-inner-icon="icon"
      :counter="bytes"
      :counter-value="counterVal"
      :rules="rule"
      class="bytes-input"
      :color="counterVal(localMdl) == bytes ? 'success' : 'primary'"
      loading
    >
      <template v-slot:progress>
        <v-progress-linear
          :value="progress"
          :color="colors"
          absolute
        ></v-progress-linear>
      </template>
    </v-text-field>
  </v-container>
</template>

<script>
export default {
  name: "ByteInput",

  props: {
    label: {
      type: String,
      required: true,
    },

    mdl: {
      default: "",
    },

    bytes: {
      default: 8,
    },

    required: {
      default: true,
    },

    icon: {
      default: "mdi-fingerprint",
    },
  },

  model: {
    prop: "mdl",
  },

  data() {
    return {
      rule: [
        (v) =>
          (v && v.trim().replace(/ /g, "").length / 2 == this.bytes) ||
          v.length == 0 ||
          this.$t("common.toFewBytes"),
      ],
    };
  },

  created() {
    if (this.required) {
      this.rule.push((v) => !!v || this.$t("common.byteInputRequired"));
    }
  },

  computed: {
    progress() {
      let temp = this.localMdl.slice();
      let res = Math.min(
        (temp.replace(/ /g, "").length / 2 / this.bytes) * 100
      );

      return res;
    },

    colors() {
      return ["error", "success"][Math.floor(this.progress / 100)];
    },

    localMdl: {
      get() {
        return this.chunk(this.mdl);
      },

      set(val) {
        this.$emit("input", val.replace(" ", "").trim());
      },
    },
  },
  methods: {
    chunk(n) {
      let temp = n.slice().trim().replace(/ /g, "");
      var space = [];
      for (var i = 0, len = temp.length; i < len; i += 2) {
        space.push(temp.substr(i, 2));
      }

      return space.join(" ").toUpperCase();
    },

    counterVal(val) {
      val = val.trim().replace(/ /g, "");
      return Math.min(val.length / 2);
    },
  },
};
</script>
<style lang="scss">
.bytes-input {
  input {
    letter-spacing: -0.5px;
    font-weight: bolder;
  }
}
</style>